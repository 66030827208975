body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.backgroundImg {
  position: fixed;
  z-index: -100;
  width: 104vw;
  height: 100vh;
  /*background-image: url("./assets/images/background.png");*/
}

.parentDiv {
  display: flex;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-image: url("./assets/images/background.png");
  background-position: left bottom;
  background-size: cover;
  background-attachment: fixed;
}

.hidden {
  display: none !important;
}

.window {
  margin: 0 auto;
  max-width: 1550px;
}

.windows {
  display: grid;
}

.navPanel {
  position: fixed;
  width: 127px;
  height: 100vh;
  background: #f2f8ff;
  transition: all 0.2s ease;
  z-index: 9999;
}

.navFixing {
  position: fixed;
  width: 127px;
  height: 100%;
  transition: all 0.2s ease;
}

.navPanelList {
  padding: 0 14px 0 25px;
  width: 127px;
  transition: all 0.2s ease;
}

.navUser {
  height: 50px;
  top: 37px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #b7bbc6;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 40px 0 20px 44px;
  transition: all 0.5s ease;
}

.navUser img {
  margin-right: 5px;
}

.navUser p {
  opacity: 0;
  transition: all 0.2s ease;
}

.navTabs {
  width: 100%;
  padding: 20px 40px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: #737373;
  list-style-type: none;
  align-items: center;
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
}

.nav__mainSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.nav__subSections {
  margin-left: 60px;
  margin-top: 0px;
  height: 0px;
  font-size: 22px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
}

#active-section {
  color: #fff;
}

.navIcons__subItem{
  display: none;
}

.navTitle:hover {
  color: #fff;
}

.nav__subSections:hover {
  color: #fff;
}

.navTabs:hover {
  cursor: pointer;
  background-color: #b7bbc6;
}

.navTabs:hover .navIcons {
  filter: brightness(0) invert(1);
}

.navTabs .navIcons {
  flex: 25%;
  display: grid;
  place-items: center;
}

.navTabs .navTitle {
  flex: 75%;
  opacity: 0;
  width: 0px;
  transition: opacity 0.2s ease;
}

#active {
  background-color: #2a47cc;
  color: #b7bbc6;
}

#active .navFlag {
  width: 51px;
  height: 80px;
  background: #2a47cc;
  border-radius: 20px;
  top: 0;
  left: -65px;
  position: absolute;
  content: "";
}

#active .navIcons {
  filter: brightness(0) invert(1);
}

#hiddenTab {
  visibility: hidden;
}

.navPanel:hover,
.navPanel:hover .navFixing,
.navPanel:hover .navFixing .navPanelList {
  width: 412px;
}

.navPanel:hover .navPanelList {
  padding: 0 52px 0 40px;
}

.navPanel:hover #active .navFlag {
  left: -78px;
}

.navPanel:hover .navUser {
  margin: 40px 0 20px 81px;
}

.navPanel:hover .navTabs .navTitle {
  opacity: 1;
  width: 100%;
}

.navPanel:hover .navUser p {
  opacity: 1;
  visibility: visible;
}

.navPanel:hover .nav__subSections {
  opacity: 1;
  visibility: visible;
  margin-top: 10px;
  height: 100%;
}

.staticElement {
  width: 127px;
  height: 100vh;
  transition: all 0.2s ease;
}

.staticElement:hover {
  width: 412px;
}

#governance {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  position: static;
  width: 197px;
  height: 36px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 60px 0px;
}

#production {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  position: static;
  width: 197px;
  height: 36px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 60px 0px;
}

#accountings {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  position: static;
  width: 122px;
  height: 50px;
  left: 0px;
  top: 96px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 60px 0px;
}

.LOGO {
  margin: auto auto 0;
  position: absolute;
  width: 284px;
  height: 51px;
  left: 20px;
  bottom: 64px;
  background: url("../src/assets/images/LOGO_small.png");
  background-repeat: no-repeat;
  mix-blend-mode: normal;
  transition: all 0.5s ease;
}

.navPanel:hover .LOGO {
  background: url("../src/assets/images/LOGO.png");
  left: 63px;
  bottom: 90px;
}

.searchBox {
  /*margin: 30px auto 30px 50px;*/
  padding: 13px;
  width: 550px;
  height: 70px;
  left: 500px;
  top: 48px;
  background: #ffffff;
  border-radius: 25px;
  justify-content: center;
  visibility: hidden;
}

.searchText {
  margin: 0;
  display: flex;
  align-items: center;
  vertical-align: middle;
  font-family: "Open Sans", "sans-serif";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  color: #aaa9a9;
}

/*"Open Sans", sans-serif*/
.mainNumber {
  font-family: "Open Sans", "sans-serif";
  font-style: normal;
  font-weight: 700;
  font-size: 97px;
  text-align: center;
  color: #324395;
  margin: auto;
}

/*TOOLTIP. TO REMOVE, WHEN SEARCH IS IMPLEMENTED*/
.tooltiptext {
  visibility: hidden;
  width: 300px;
  font-family: "Open Sans", sans-serif;
  background-color: #b7bbc6;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.searchBox:hover .tooltiptext {
  visibility: visible;
}

/*END OF TOOLTIP*/

#DownloadButton {
  background-color: #2a47cc;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: #fff;
  padding: 20px 0;
  margin: 0 auto;
  border-radius: 100px;
  border: none;
  width: 270px;
  height: 70px;
  letter-spacing: 2px;
}

/*MEDIA*/

@media screen and (max-width: 1660px) {
  .navPanel:hover,
  .navPanel:hover .navFixing,
  .navPanel:hover .navFixing .navPanelList,
  .staticElement:hover {
    width: 300px;
  }

  .navPanel:hover .navPanelList {
    padding: 0 14px 0 25px;
  }

  .navPanel:hover #active .navFlag {
    left: -65px;
  }

  .navPanel:hover .navUser {
    margin: 40px 0 20px 44px;
  }

  .navPanel:hover .LOGO {
    left: 20px;
    bottom: 64px;
    width: 254px;
    height: 47px;
    background-size: cover;
  }
}

@media screen and (max-width: 1300px) {
  .navPanel:hover,
  .navPanel:hover .navFixing,
  .navPanel:hover .navFixing .navPanelList,
  .staticElement:hover {
    width: 127px;
  }

  .navPanel:hover .navPanelList {
    padding: 0 14px 0 25px;
  }

  .navPanel:hover #active .navFlag {
    left: -65px;
  }

  .navPanel:hover .navUser {
    margin: 40px 0 20px 44px;
  }
  .navTabs .nav__subSections p{
    display: none;
  }
  .navPanel:hover .navUser p,
  .navPanel:hover .navTabs .navTitle,
  .navPanel:hover .navTabs .nav__subSections p
   {
    display: none;
  }
  #active .navIcons {
    filter: none;
  }

  #active-icon *{
    filter: brightness(0) invert(1);
  }

  .navPanel:hover .LOGO {
    left: 20px;
    bottom: 64px;
    background: url("../src/assets/images/LOGO_small.png");
    background-repeat: no-repeat;
    display: none;
  }

  .window {
    margin-left: 70px;
  }

  .navIcons__subItem{
    display: block;
  }

  .navIcons__subItem img{
    transform: translateX(-40%) scale(1.05);
    margin-top: 15px;
  }
}

@media screen and (max-width: 670px) {
  .navPanel,
  .navPanel .navFixing,
  .navPanel .navFixing .navPanelList,
  .staticElement {
    width: 90px;
  }

  .navPanel:hover,
  .navPanel:hover .navFixing,
  .navPanel:hover .navFixing .navPanelList,
  .staticElement:hover {
    width: 90px;
  }

  .navPanel .navUser {
    margin: 40px 0 20px 19px;
  }

  .navPanel:hover .navUser {
    margin: 40px 0 20px 19px;
  }

  .navPanel:hover .navPanelList {
    padding: 0 4px 0 4px;
  }

  .navPanelList {
    padding: 0 4px 0 4px;
  }

  .navPanel .LOGO {
    left: -10px;
    transform: scale(0.9);
  }

  .navPanel:hover .LOGO {
    transform: scale(0.9);
    left: -10px;
  }

  .window {
    margin-left: 100px;
    transform-origin: top left;
    transform: scale(0.8);
  }
}

@media screen and (max-width: 565px) {
  .window {
    margin-left: 115px;
    transform: scale(0.7);
  }
}

@media screen and (max-width: 500px) {
  .window {
    transform: scale(0.6);
  }
}

@media screen and (max-width: 425px) {
  .window {
    margin-left: 100px;
    transform: scale(0.5);
  }
}

@media screen and (max-width: 375px) {
  .window {
    margin-left: 88px;
  }
}

@media screen and (max-width: 360px) {
  .window {
    margin-left: 78px;
  }
}

/* Calendar */

.caldendarDark {
  position: fixed;
  left: 0;
  top: 0;
  width: 1000vw;
  height: 1000vh;
  background-color: #000;
  opacity: 0.15;
  z-index: 1;
}

.calendarWrap {
  display: inline-block;
  z-index: 9999;
  position: relative;
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
  background: #ffffff;
  border-radius: 26.7529px;
  z-index: 999;
}

.rdrMonth {
  width: 400px !important;
}

.rdrMonthAndYearPickers select {
  padding: 0 !important;
  display: none;
}

.rdrMonthName {
  /* font-family: 'IBM Plex Sans'; */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22.5287px;
  line-height: 34px;
  letter-spacing: 0.168966px;
  color: #132c14 !important;
  text-align: center !important;
  margin-top: -60px;
}

.rdrNextPrevButton i {
  border: none !important;
  width: 20px !important;
  height: 37px !important;
}

.rdrNextButton {
  background-image: url("assets/images/ArrowForward.svg") !important;
  background-color: transparent !important;
  height: 37px !important;
  margin-right: 93px !important;
}

.rdrPprevButton {
  background-image: url("assets/images/ArrowBack.svg") !important;
  background-color: transparent !important;
  height: 37px !important;
  margin-left: 80px !important;
}

.rdrWeekDays {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14.0805px;
  line-height: 23px !important;
  letter-spacing: 2.11207px;
  text-transform: uppercase;
  color: #132c14 !important;
}

.rdrDays {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 19.7126px;
  line-height: 31px;
  letter-spacing: 0.30977px;
}

.rdrDays * {
  outline: none;
}

.rdrStartEdge {
  color: #324395 !important;
}

.rdrInRange {
  color: #324395 !important;
}

.rdrEndEdge {
  color: #324395 !important;
}

.rdrStartEdge::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  background-color: #ef5da8;
  border-radius: 50%;
  padding: 27px;
  margin-top: -2px;
  margin-left: -3px;
}

.rdrEndEdge::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  background-color: #ef5da8;
  border-radius: 50%;
  padding: 27px;
  margin-top: -2px;
}

.rdrDayNumber > span::after {
  display: none !important;
}

/* .rdrDayHovered *{
  border: none !important;
} */

/* Production window */

.toggle-column{
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.toggle-column input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.toggle-column label {
	cursor: pointer;
	text-indent: -9999px;
	width: 120px;
  height: 68px;
	background:#aaa9a9;
	display: block;
	border-radius: 100px;
	position: relative;
}

.toggle-column .toggleName{
  color: white;
  font-size:25px;
  margin-right: 10px;
}
.toggle-column label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
  width: 60px;
  height: 60px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.toggle-column input:checked + label {
	background: #324395;
}

.toggle-column input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.toggle-column label:active:after {
	width: 130px;
}


.productionWindow {
  margin: 0 auto;
  max-width: calc(100% - 127px);
  min-width: calc(100% - 412px);
  padding-bottom: 65px;
}

.hswWindow {
  margin: 0 auto;
  max-width: calc(100% - 127px);
  min-width: calc(100% - 412px);
  padding-bottom: 65px;
}

.production__block-top {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 48px auto 0;
  max-width: 1316px;
  padding: 0 15px;
  transition: all 0.2s ease;
}

.hsw__block-top {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 48px auto 0;
  max-width: 1316px;
  padding: 0 15px;
  transition: all 0.2s ease;
}

.chart__block-top {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 48px;
  padding: 0 15px;
  transition: all 0.2s ease;
}

.chart__block-top

.production__block-top-large {
  max-width: 1400px;
}

.production__left-column {
  width: 49%;
}

.production__left-column input {
  padding: 17px 51px 17px 90px;
  gap: 10px;
  width: 100%;
  height: 68px;
  background-color: #ffffff;
  border: none;
  box-shadow: 10px 10px 50px 20px rgba(39, 56, 131, 0.2);
  border-radius: 100px;
  background-image: url("assets/images/Search.svg");
  background-repeat: no-repeat;
  background-position: center left 45px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 132%;
  color: #aaa9a9;
  outline: none;
}

.production__left-column input::placeholder {
  color: #aaa9a9;
}

.production__right-column {
  display: flex;
  justify-content: space-between;
  width: 49%;
}

.hsw__right-column {
  display: flex;
  justify-content: space-between;
  width: 37%;
}

.chart__right-column{
  width: unset!important;
  padding-left: 20px;
}

.production__right-column span svg {
  font-size: 28px;
}

.production__right-column input {
  padding: 17px 32px 17px 77px;
  gap: 10px;
  width: 461px;
  height: 68px;
  background-color: #ffffff;
  box-shadow: 10px 10px 50px 20px rgba(39, 56, 131, 0.2);
  border: none;
  border-radius: 100px;
  background-image: url("assets/images/CalendarIcon.svg");
  background-repeat: no-repeat;
  background-position: center left 30px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 132%;
  color: #aaa9a9;
  outline: none;
}

.hsw__right-column input {
  padding: 17px 32px 17px 77px;
  gap: 10px;
  width: 461px;
  height: 68px;
  background-color: #ffffff;
  box-shadow: 10px 10px 50px 20px rgba(39, 56, 131, 0.2);
  border: none;
  border-radius: 100px;
  background-image: url("assets/images/CalendarIcon.svg");
  background-repeat: no-repeat;
  background-position: center left 30px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 132%;
  color: #aaa9a9;
  outline: none;
}

.timerIcon {
  background-image: url("assets/images/TimerIcon.svg");
  width: 50px;
  height: 50px;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 5px;
}

.checkboxRush{
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.strikethrough {
  text-decoration: line-through;
}

.checkboxGroup {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkboxRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 300px;
}

.checkboxRow label {
  margin-left: 5px;
  color: #324395;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.production__right-column input::placeholder {
  color: #aaa9a9;
}

.production__right-column > select {
  padding: 15px 32px;
  width: 153px;
  height: 67px;
  background-color: #ffffff;
  box-shadow: 10px 10px 50px 20px rgba(39, 56, 131, 0.2);
  border-radius: 100px;
  background-image: url("assets/images/DropdownArrow.svg");
  background-repeat: no-repeat;
  background-position: center right 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;

  color: #444444;
  outline: none;
}

.chartWindow{
  max-width: calc(100% - 127px);
  min-width: calc(100% - 412px);
  padding-bottom: 65px;
}

.chart__graph{
  background: #ffffff;
  box-shadow: 11.4769px 11.4769px 34.4308px 11.4769px rgba(0, 0, 0, 0.25);
  border-radius: 34.4308px;
  margin-top: 42px;
  transition: all 0.2s ease;
}



.chart__graph .graph-title {
  padding: 26px 29px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart__graph .graph-title div {
  display: flex;
}

.chart__graph .graph-title span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  color: #324395;

  margin-left: 12px;
}
.production__graphs-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1312px;
  margin: 0 auto;
  padding: 0 15px;
  transition: all 0.2s ease;
}
.hsw__graphs-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1312px;
  margin: 0 auto;
  padding: 0 15px;
  transition: all 0.2s ease;
}

.production__graph {
  background: #ffffff;
  box-shadow: 11.4769px 11.4769px 34.4308px 11.4769px rgba(0, 0, 0, 0.25);
  border-radius: 34.4308px;
  margin-top: 42px;
  transition: all 0.2s ease;
}

.hsw__graph {
  background: #ffffff;
  box-shadow: 11.4769px 11.4769px 34.4308px 11.4769px rgba(0, 0, 0, 0.25);
  border-radius: 34.4308px;
  margin-top: 42px;
  transition: all 0.2s ease;
  overflow: hidden;
  width: 100%;
  padding-bottom: 12px;
}

.emptyHSWCell{
  background: #cacaca;
}

/* Apply background color to entire row */
.eveningRow {
  background-color: #ECECEC;
  color: #000;
}

.nightRow {
  background-color: #D4D4D4;
  color: #000;
}

.HSWrow td {
  background: inherit;
}

.gradientCell {
  color: #000; /* Ensure contrast with gradient */
  transition: background 0.3s ease; /* Smooth transitions */
}

.gradientIntensity {
  color: inherit;
}

.production__graph:nth-child(1) {
  width: 49%;
}

.production__graph:nth-child(2) {
  width: 49%;
}

.production__graph:nth-child(3) {
  overflow: scroll;
  width: 100%;
  padding-bottom: 12px;
}

.production__graph .graph-title {
  padding: 26px 29px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hsw__graph .graph-title {
  padding: 26px 29px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.production__graph .graph-title div {
  display: flex;
}

.hsw__graph .graph-title div {
  display: flex;
}

.production__graph .graph-title span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  color: #324395;
  margin-left: 12px;
}

.hsw__graph .graph-title span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  color: #324395;
  margin-left: 12px;
}

@media (max-width: 450px) {
  .production__graph .graph-title span {
    font-size: 20px;
  }
}

.production__graph .graph-title > img {
  cursor: pointer;
}

@media (max-width: 450px) {
  .hsw__graph .graph-title span {
    font-size: 20px;
  }
}

.hsw__graph .graph-title > img {
  cursor: pointer;
}

.HSWTable{

}

.HSWheader{
  font-size: 13px !important;
}

.graph-close > img {
  cursor: pointer;
}

.production__graph .graph-title .graph-close span {
  font-size: 20px;
  line-height: 93%;
  color: #aaa9a9;
  margin-right: 10px;
}

/* Slider */

.swiper {
  padding-bottom: 65px !important;
}

.swiper .production__graph {
  width: 100%;
}

.swiper-pagination-bullet {
  width: 24px !important;
  height: 24px !important;
  background: #ffffff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #ef5da8 !important;
}

.close-menu:hover {
  width: 127px;
}

.close-menu .navPanel:hover,
.close-menu .navPanel:hover .navFixing,
.close-menu .navPanel:hover .navFixing .navPanelList,
.close-menu .staticElement:hover {
  width: 127px;
}

.close-menu .navPanel:hover .navPanelList {
  padding: 0 14px 0 25px;
}

.close-menu .navPanel:hover #active #navFlag {
  left: -65px;
}

.close-menu .navPanel:hover .navUser {
  margin: 40px 0 20px 44px;
}

.close-menu .navPanel:hover .navUser p,
.close-menu .navPanel:hover .nav__subSections,
.close-menu .navPanel:hover .navTabs .navTitle {
  display: none;
}

.close-menu .navPanel:hover .LOGO {
  left: 20px;
  bottom: 64px;
  background: url("../src/assets/images/LOGO_small.png");
  background-repeat: no-repeat;
  display: block;
}

.close-menu .navPanel:hover .navPanelList {
  padding: 0 14px 0 25px;
}

.close-menu .navPanel:hover #active .navFlag {
  left: -65px;
}

@media (max-width: 1300px) {
  .production__left-column {
    width: 100%;
  }

  .production__right-column {
    width: 100%;
    margin-top: 20px;
  }

  .inputBox {
    width: 70vw !important;
  }

  .production__graph:nth-child(1) {
    width: 100%;
  }

  .production__graph:nth-child(2) {
    width: 100%;
  }
}

@media (max-width: 950px) {
  .inputBox {
    width: 60vw !important;
  }
}

@media (max-width: 700px) {
  .inputBox {
    width: calc(100vw - 157px) !important;
  }

  .production__right-column > select {
    width: 100%;
    margin-top: 15px;
  }

  .production__right-column {
    flex-wrap: wrap;
  }
}

.graphs-opacity {
  opacity: 0;
  height: 0px;
}

.graphs-opacity * {
  opacity: 0;
  height: 0px;
}



/* //TableChart */
.point-container table,
.point-container th,
.point-container td {
  border: 1px solid #aaa9a947 !important;
  border-collapse: separate;
    border-spacing: 0;
  padding: 2px 4px;
  font-size: 8.5px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-variant: normal;
  font-family: Inter, sans-serif;
  color: #324395;
}

.point-container th {
  padding: 0 10px;
}
.point-container table{
  border: none!important;
}
.point-container th.slider {
  padding: 0;
}

.point-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pointsTable {
  position: relative;
  border: none!important;
}

.point-container.full th {
  background: white;
  position: sticky;
  top: 17px;
  height: 25px;
  width: 100%;
}

.pointsTable.full {
  overflow-y: scroll;
  height: 500px;
  display: block;
  background-color: white;
  transition: .5s;
}
.point-container.full table {
  border: none !important;
}

.pointsTable.full::-webkit-scrollbar {
  width: 3px;
}
.pointsTable.full::-webkit-scrollbar-thumb {
  background: #324395;
  border-radius: 5px;
}

.pointsTable.full .tableName > th:nth-child(7),
.pointsTable.full .tableName > th:nth-child(12),
.pointsTable.full .tableName > th:nth-child(17) {
  position: sticky;
}

.pointsTable.full .tableName > th:nth-child(7) {
 border-right: 1px solid #324395!important;
}

.pointsTable.full .tableName > th:nth-child(7)::before,
.pointsTable.full .tableName > th:nth-child(12)::before,
.pointsTable.full .tableName > th:nth-child(17)::before {
  display: none;
}

/* ////////////////////////////////////////// */
.app,
.containers {
  width: 100%;
  height: 100%;
}

.input-like {
  display: inline-block;
  padding: 2px 15px;
  border: 1px solid #8080803b;
  border-radius: 1px;
  box-sizing: border-box;
  width: 50px;
  text-align: center;
}

.table-input {
  outline: none;
  border: 1px solid #8080803b;
  width: 50px;
  text-align: center;
}
.containers {
  font-size: 28px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #283c34;
  text-align: center;
}

.bottomSetup {
  width: 100%;
  background-color: #c4c4c4;
  height: 19px;
  margin-top: -22px;
  display: flex;
  padding: 1px 20px;
  justify-content: center;
  position: relative;
}

.eveningTitle {
  margin-bottom: 0;
  position: absolute;
}

.morningTitle {
  margin-bottom: 0;
  position: absolute;
}

.shiftTitle{
  background: #ffffff;

}
.shiftTitle th {
  border: none!important;
  font-size: 13px;
  top: -4px!important;
}

.arrow_box {
  background: #ffffff;
  padding: 5px;
  padding-left: 25px;
}

.arrow_box .serie {
  display: flex;
}

.morning,
.evening,
.daily {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.arrow_box .serie .shift:before {
  content: " ";
  position: absolute;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
}

.arrow_box .serie .morning:before {
  background-color: #3347e6;
}

.arrow_box .serie .evening:before {
  background-color: #ef5da8;
}

.arrow_box .serie .daily:before {
  background-color: #801482;
}

.loading-title {
  margin-bottom: 0;
}

.serie {
  margin-bottom: 5px;
}

.serie-title {
  font-size: 12px;
  color: #000;
  margin-bottom: 0;
  font-weight: 700;
}

.serie-value {
  padding-left: 5px;
}

.values {
  display: flex;
  width: 100%;
}

.tableName > th:nth-child(7) {
  position: relative;
}

.tableName > th:nth-child(8),
.tableName > th:nth-child(9),
.tableName > th:nth-child(10),
.tableName > th:nth-child(11),
.tableName > th:nth-child(12),
.tableName > th:nth-child(13),
.tableName > th:nth-child(14),
.tableName > th:nth-child(15),
.tableName > th:nth-child(16),
.tableName > th:nth-child(17),
.tableName > th:nth-child(18),
.tableName > th:nth-child(19) {
  border-right: 1px solid #324395 !important;
  border-top: 1px solid #324395 !important;
  border-bottom: 1px solid #324395 !important;
}

.tableName > th:nth-child(12) {
  position: relative;
}

.tableName > th:nth-child(17) {
  position: relative;
}

.tableName > th:nth-child(7)::before,
.tableName > th:nth-child(13)::before,
.tableName > th:nth-child(19)::before {
  position: absolute;
  content: "";
  right: -1px;
  height: 33px;
  background-color: #324395;
  width: 1px;
  bottom: 0;
}

@media (max-width: 1200px) {
  .tableName > th:nth-child(7)::before,
  .tableName > th:nth-child(13)::before,
  .tableName > th:nth-child(19)::before {
    display: none;
  }
}

.bottomSetup span {
  font-weight: 700;
  font-size: 10px;
  padding-left: 10px;
  position: absolute;
  left: 0;
}

.setups {
  display: flex;
  list-style-type: none;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 0;
  padding: 0;
  padding-left: 30px;
}

.setups li {
  font-size: 11px;
  width: calc(100% / 6);
}


.morningstart {
  position: absolute;
  left: 54%;
  top: -22px;
  font-size: 14px;
  color: #324395;
}
.point-container.full {
  position: relative;
}
.point-container.full .morningstart {
  position: absolute;
  top: -10px;
  font-size: 14px;
}

.eveningstart {
  position: absolute;
  left: 76%;
  top: -22px;
  font-size: 14px;
  color: #324395;
  
}
.point-container.full .eveningstart {
  position: absolute;
  left: 76%;
  top: -10px;
  font-size: 14px;
}

.qty {
  display: flex;
  list-style-type: none;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 0;
  padding: 0;
  padding-left: 30px;
}

.qty li {
  font-size: 11px;
  width: calc(100% / 6);
}

.bottomQty {
  margin-top: 6px;
  width: 100%;
  background-color: #c4c4c4;
  height: 19px;
  display: flex;
  padding: 1px 20px;
  justify-content: center;
  position: relative;
}

.bottomQty span {
  font-weight: 700;
  font-size: 10px;
  padding-left: 10px;
  position: absolute;
  left: 0;
}

@media (max-width: 1300px) {
  .chart-bar {
    padding-bottom: 28px;
  }
}

/* Scheduling */

.schedulingDataWindow {
  margin: 70px auto 0;
  padding: 25px;
  width: 1100px;
  background: #ffffff;
  box-shadow: 11.5px 11.5px 35px 11.5px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  justify-content: center;
  position: relative;
}

.scheduling-table {
  margin: 35px 20px 0 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 32px;
  box-shadow: 10px 10px 50px 20px rgba(39, 56, 131, 0.1);
}

.scheduling-table__title th {
  padding: 15px 5px;
  text-align: center;
}

.scheduling-table__title th:nth-child(1) {
  width: 62%;
}
.scheduling-table__title th:nth-child(2) {
  width: 30%;
}

.scheduling-table__row td {
  padding: 2px 10px;
}

.scheduling-table__row input {
  border: none;
  background: none;
  width: 98%;
  outline: none;
}

.scheduling-table__resolve-icon {
  margin: 0 10px;
  cursor: pointer;
}

.scheduling-table__plus {
  font-size: 70px;
  font-weight: 600;
  margin-left: auto;
  margin-right: 35px;
  margin-top: 20px;
  cursor: pointer;
  color: #fff;
  background: #324395;
  padding: 0px 17px 12px;
  line-height: 100%;
  border-radius: 50%;
}

.scheduling-table__row:nth-child(2n) {
  background-color: rgba(39, 56, 131, 0.07);
  font-size: 33px;
}

.scheduling__add-new-background {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.35);
}

.scheduling__add-new-window {
  position: absolute;
  top: 9%;
  left: 50%;
  transform: translate(-50%);
  background: #fff;
  box-shadow: 11.5px 11.5px 35px 11.5px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  padding: 55px;
  outline: none;
}

.scheduling__add-new-window h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  margin-bottom: 30px;
  color: #324395;
}

.scheduling__add-new-window label {
  display: block;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  margin: 0 0 7px 20px;
}

.scheduling__add-new-window input {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #444444;
  margin: 0 auto 15px;
  padding: 22px 32px;
  width: 521px;
  height: 69px;
  background: #ffffff;
  box-shadow: 10px 10px 50px 20px rgba(39, 56, 131, 0.1);
  border-radius: 100px;
  border: none;
  outline: none;
}

.scheduling__add-new-window .wrong {
  box-shadow: 0px 0px 20px red;
}

.scheduling__add-new-window__button {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: #fff;
  padding: 20px 0;
  margin: 0 auto;
  background: #2a47cc;
  border-radius: 100px;
  border: none;
  width: 521px;
  height: 70px;
  letter-spacing: 2px;
  margin-top: 35px;
}

.react-datepicker {
  transform: scale(1.5) translateY(15%) translateX(15%);
}
.react-datepicker__triangle {
  left: -50% !important;
}


.Timer{
  text-align: left !important;
}

@media screen and (max-width: 991px) {
  .Timer{
    text-align: center !important;
  }
}




.ValuesTable *{
  
  font-weight: 500;
  font-size: 24px;
  padding: 0px 5px !important;
  border: none;
  color: #324395;
  text-align: right;
}

.ValuesTable__Label{
  font-weight: 700;
  text-align: left;
}

.ValuesTable__text-right{
  text-align: right;
}

@media screen and (max-width: 1100px) {
  .ValuesTable *{
    font-size: 25px;
  }
}
@media screen and (max-width: 850px) {
  .ValuesTable *{
    font-size: 20px;
    padding: 3px 5px !important;
  }
}

@media (max-width: 1450px) and (min-width: 1300px) {
  .ValuesTable *{
    font-size: 21px;
  }
  .ValuesTable{
    border-collapse:separate; 
    border-spacing: 0 0.3em;
  }
}

@media (max-width: 1300px) and (min-width: 1150px) {
  .ValuesTable *{
    font-size: 16px;
  }
  .ValuesTable{
    border-collapse:separate; 
    border-spacing: 0 0.7em;
  }
}

@media (max-width: 1150px) and (min-width: 992px) {
  .ValuesTable *{
    font-size: 13px;
  }
  .ValuesTable{
    border-collapse:separate; 
    border-spacing: 0 1em;
  }
}
